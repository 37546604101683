.naszePiwa{
    margin: 3rem 0;
    background: none;
    &__title {
        font-family: 'caviar_dreamsbold';
        font-size: 30px;
        line-height: 44px;
        padding: 2rem 0 1rem 0;
        text-transform: uppercase;
        text-align: center;
    }
    &__Text{
        padding: 0 20px;
        color: #4f4f4f;
        font-size: 14px;
        line-height: 21px;
        font-family: Poppins,sans-serif;
    }
    &__icon{
        margin: 0 auto;
        img {
            text-align: center;
            margin: auto;
        }
    }
}