/** header dla podstron **/

body.page,
body.single,
body.single-modul_oferty_specjal {
    .header {
        &__slideContainer {
            padding: 0;
            height: 100vh;
            cursor: auto;
            @media (max-width: 991px) {
                height: 50vh;
            }
            &:hover{
                h2.header__title{
                    font-size: 4.5rem;
                    transition: 1s
                }
            }
        }
    &__slide {
        background-size: cover;
        height: 100vh;
        width: 100%;
        background-position: center;
        padding: 0;    
        @media (max-width: 991px) {
            height: 50vh;
        }
    }
        &__menuItem {
            a {
                color: white;
                text-transform: uppercase!important; 
                /* font-family: 'caviar_dreamsbold'; */
                font-family: 'lato';
                font-weight: 500;
                display: inline-block;
                vertical-align: middle;
                -webkit-transform: perspective(1px) translateZ(0);
                transform: perspective(1px) translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                &:hover{
                    -webkit-transform: scale(0.9);
                    transform: scale(0.9);
                    text-decoration: none;
                }
            }
            text-transform: uppercase!important;
            font-family: 'caviar_dreamsbold';
            font-size: 30px;
            line-height: 72px;
            text-align: center;
            position: absolute;
            z-index: 10;
            top: 0;
            text-align: center;
            &--first {               
                left: 0%;                
            }
            &--second {
                left: 33%;  
            }
            &--third {
                left: 66%;  
            }
            &.active a{
                color: #9E9E9E!important;
            }
        }
        h2.header__title {
            &:hover {
                font-size: 60px;
            }
        }
        &__socialMedia {
            position: relative;
            z-index: 6;
            margin: -10rem 0 0 auto;
            float: right;
            @media (max-width: 991px) {
                margin: -50rem 0 0 auto;
            }
        }
    }
}

body.page {
    .header__slide--slide1 {    
        background-color: rgba(0, 0, 0, .2);
        background-image: url("../../dist/images/slide_ogolny.jpg");
        background-size: cover;
            @media (max-width: 991px) {
                background-position: 0;
            }
        }
    }

body.page-template-page-restauracja {
    .header__slide--slide1 {    
        background-image: url("../../dist/images/slide1_restauracje.jpg")!important;
        background-size: cover;
            @media (max-width: 991px) {
                background-position: 0;
            }
        }
    }

body.page-template.page-template-page-minibrowar.page-template-page-minibrowar-php.page.page-id-38 {
    .header__slide--slide1 {    
        background-image: url("../../dist/images/slide2_minibrowar.jpg")!important;
        background-size: cover;
            @media (max-width: 991px) {
                background-position: 0;
            }
        }
    }


body.page-template.page-template-page-eventy.page-template-page-eventy-php.page.page-id-28 {
    .header__slide--slide1 {    
        background-image: url("../../dist/images/slide3_eventy.jpg")!important;
        background-size: cover;
            @media (max-width: 991px) {
                background-position: 0;
            }
        }
    }
  

body.modul_oferty_specjal-template-default,
body.single {
    .header__slide--slide1 {    
        background-image: url("../../dist/images/slide4_oferty.jpg")!important;
        background-size: cover;
            @media (max-width: 991px) {
                background-position: 0;
            }
        }
    }

body.page-template-page-onas {
    .header__slide--slide1 {    
        background-image: url("../../dist/images/slide_onas.jpg");
        background-size: cover;
            @media (max-width: 991px) {
                background-position: 0;
            }
        }
    }

body.page-template-page-galeria {
    .header__slide--slide1 {    
        background-image: url("../../dist/images/slide_galeria.jpg");
        background-size: cover;
            @media (max-width: 991px) {
                background-position: 0;
            }
        }
    }

body.home {
    .header {
        &__slideContainer {
            cursor: pointer;
        }
    }
}