.wesela{    
    background-color: #e9e9ea !important;
    padding-top: 3rem;
    .container-fluid {
        background-color: #E9E9EA;
        padding: 0;
    }
    
    &__tytul {
        font-size: 30px;
        text-transform: uppercase;
        font-family: 'caviar_dreamsbold';
        text-align: center;
        margin: 1rem 0 2rem 0;
    }
    &__naszeHale{        
        padding: 0 0 3rem 0;
        background: none;
        &__title {
            font-family: 'caviar_dreamsbold';
            font-size: 30px;
            line-height: 44px;
            padding: 0;
            text-transform: uppercase;
            text-align: center;
        }
        &__Text{
            padding: 0;
            color: #4f4f4f;
            font-size: 14px;
            line-height: 21px;
            font-family: Poppins,sans-serif;
            margin: 0 -15px;
        }
    }
}