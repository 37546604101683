.specialOffers{
    .container-fluid {
        background-color: #E9E9EA;
        padding: 3rem 0;
    }
    h2.specialOffers__title {
        font-family: 'caviar_dreamsbold';
        font-size: 30px;
        line-height: 44px;
        margin-bottom: 1rem;
        text-transform: uppercase;
        text-align: center;
    }
    h3.specialOffers__subheader {
        font-family: 'caviar_dreamsbold';
        /* font-size: 24px; */
        font-size: 22px;
        line-height: 44px;
        margin: 1rem 0;
        text-transform: uppercase;
        text-align: center;
    }
    &__text {
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        /* font-size: 14px; */
        line-height: 21px;
        color: #4F4F4F;
        @include MainTextSize;
    }
    &__module {
        @media (max-width: 991px) {
            margin-top: 4rem;
        }
    }
}
