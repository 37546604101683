$breakpoints: (
    xsmall: (min-width: 460px), 
    small: (min-width: 576px), 
    medium: (min-width: 768px), 
    large: (min-width: 992px),  
    xlarge: (min-width: 1200px), 
    xxlarge: (min-width: 1410px)
);

@mixin media($breakpoint) {
    $size: map-get($breakpoints, $breakpoint);
    @if($size) {
      @media #{$size} {
        @content;
      }
    } @else {
      @error '"#{$breakpoint}" - błąd, nie wiem co to';
    }
}  
 
@mixin RoomBG($bgcolor, $color) {
	color: $color;
	background-color: $bgcolor;
}   

//uzycie
// @include media(xsmall) {
//     font-size:18px;
// }

@mixin HeaderSliderFirst {
  font-family:  Lato,sans-serif;
}
@mixin HeaderSliderSecond {
  font-family:  Lato,sans-serif;
}

@mixin headReservationFont {
    font-family:  Lato,sans-serif;
}
@mixin formFont {
    font-family:  Lato,sans-serif;
}
@mixin ButtonFont {
    font-family:  Lato,sans-serif;
}

@mixin subTitleFontLight {
    font-family: Lato,sans-serif;
}


@mixin headSpecialOffer {
    font-family: Lato,sans-serif;
}

@mixin headFontLight {
    font-family:  Lato,sans-serif;
}

@mixin headFontMid {
  font-family:   Lato,sans-serif;
	 font-weight: 500;
}

@mixin titleFontBold {
  	font-family:  Lato,sans-serif;
	font-weight: 700;
}

@mixin headFontBold {
	font-size: 14px;
	@media(min-width:576px){
		font-size: 34px;
	}
	@media(min-width:768px){
		font-size: 38px;
	}
	@media(min-width:992px){
		font-size: 52px;
	}
}
@mixin headFontSemiBold {
  	font-family:  Lato,sans-serif;
	font-weight: 500;
	font-style: normal;
}

@mixin on-event($self: false) {
    @if $self {
      &,
      &:hover,
      &:active,
      &:focus {
        @content;
      }
    } @else {
      &:hover,
      &:active,
      &:focus {
        @content;
      }
    }
}

@mixin overlay($bgColor: #000, $bgOpacity: 1){
  .overlay{
      background:rgba($bgColor, $bgOpacity);
      position:absolute;
      width:100%;
      height:100%;
      left:0;
      top:0;
  }
}


@mixin HeadFont48 {
  font-family: Lato,sans-serif;
  font-size: 48px;
}
@mixin HeadFont36 {
  font-family: Lato,sans-serif;
  font-size: 36px;
}
@mixin FooterFont {
  font-family: 'Montserrat', sans-serif;
	font-weight: 400;
}

@mixin MainTextSize {
  font-size: 13px;
  text-align: justify;
}