.header  {
    .menu-item .nav-link {
        color: white;
        text-transform: uppercase!important; 
        /* font-family: 'caviar_dreamsbold'; */
        font-family: 'lato';
        font-weight: 500;
        padding-left: 15px;
        padding-right: 15px;
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
        &:hover{
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
        }
    }
    &__socialMedia__main {
        position: relative;
        z-index: 6;
        margin: -10rem 3rem 0 auto;
        float: right;
        
        img {
            height: 18px;
            display: flex;
            position: relative;
            top: 50%;
            transform: translate(-50%);
        }
        img.header__icon2 {
            margin-left: 15px;
        }
        img.header__icon3 {
            margin-left: 10px;
        }
        @media (max-width: 991px) {
            margin: -153rem 0 0 auto;
        }
    }
    
    &__socialMedia__fixed {
        position: fixed;
        top: 0;
        width: 120px;
        //background-color: rgba(255,255,255,0.8);
        margin: 0 10px 0 0;
        padding: 10px 0;
        text-align: right;
        z-index: 6;
        right: 0;
        .header__socialMedia__icon{
            margin-right: 10px;
        }
        display: none;
    }


    .navbar {
        position: relative;
        z-index: 6;
        margin: -10rem -15px 0 -15px;
        @media (max-width: 991px) {
            margin: -153rem auto 0 0;
        }
    }
    #navbarNavDropdown {
        padding-left: 3rem;
    }
}





