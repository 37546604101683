.body {
    font-family: 'caviar_dreamsbold';
}
.container-fluid{
    padding: 0;
}
.row {
    margin-left: 0;
    margin-right: 0;
}
.mainContainer{
    max-width: 1410px;
    margin:0 auto;

    @media (min-width:992px) and (max-width:1199px){
        padding-left:25px;
        padding-right:25px;
    }

    @media (min-width:1200px) and (max-width:1500px){
        padding-left:50px;
        padding-right:50px;
    }
}

.bold{
    @include headFontBold;
}

a{
    color:#000;
}

/* BACKGROUND */



/* COLORS */


/*  others */


p{
    font-size: 13px;
    text-align: justify;
}

@media(min-width:768px){
    p{
        font-size:13px;
    }
}

@media(min-width:1200px){
    p{
        font-size:13px;
    }
}

.slide{
    width:100%;
    height:100%;
    position:relative;
}

.psuedo-background-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.subtitleSection{

    @include media(xsmall) {
        font-size:18px;
    }

    @include media(small) {
        font-size:18px;
    }

    @include media(medium) {
        font-size:20px;
    }

    @include media(large) {
        font-size:24px;
    }

    @include media(xxlarge) {
        font-size:28px;
    }

    strong,
    &--bold{
        @include headFontBold;
    }

    &--small{
        font-size:26px;

        @include media(xsmall) {
            font-size:28px;
        }
    
        @include media(small) {
            font-size:30px;
        }
    
        @include media(medium) {
            font-size:32px;
        }
    
        @include media(large) {
            font-size:34px;
        }
    
        @include media(xxlarge) {
            font-size:36px;
        }
    }

    &--xsmall{
        font-size:16px;

        @include media(xsmall) {
            font-size:18px;
        }

        @include media(medium) {
            font-size:18px;
        }
 
    }

}
.titleSection{
    
    @include headFontBold;

    @include media(xsmall) {
        font-size:33px;
    }

    @include media(small) {
        font-size:36px;
    }

    @include media(medium) {
        font-size:38px;
    }

    @include media(large) {
        font-size:40px;
    }

    @include media(xxlarge) {
        font-size:52px;
    }

    strong,
    &--bold{
        @include headFontBold;
    }

    &--small{
        font-size:26px;

        @include media(xsmall) {
            font-size:28px;
        }
    
        @include media(small) {
            font-size:30px;
        }
    
        @include media(medium) {
            font-size:32px;
        }
    
        @include media(large) {
            font-size:34px;
        }
    
        @include media(xxlarge) {
            font-size:36px;
        }
    }

    &--xsmall{
        font-size:20px;

        @include media(xsmall) {
            font-size:22px;
        }

        @include media(medium) {
            font-size:24px;
        }
 
    }

}

//body.home.page-template.page-template-front-page.page-template-front-page-php.page.page-id-32, body.page-template.page-template-page-restauracja.page-template-page-restauracja-php.page.page-id-30, body.page-template.page-template-page-minibrowar.page-template-page-minibrowar-php.page.page-id-38, body.page-template.page-template-page-eventy.page-template-page-eventy-php.page.page-id-28 {
    body {
    padding-top: 0!important;
}

.hidden{
    display: none!important;
}
.show{
    display: block!important;
}



.novisible{
    display: none!important;
}
.showvisible{
    display: block!important;
    padding: 0 !important;
}
.icon {
	display: inline-block;
	width: 30px;
	height: 30px;
	vertical-align: middle;
	fill: #ffffff;
}

.centerOfScreen{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); /* Yep! */
        width: 48%;
        height: 59%;
}