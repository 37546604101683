.mainSection {
    &__fixed{
        position: fixed;
        top: 0;
        margin: 0;
        padding: 0;
        left: 50%;
        transform: translate(-50%,-15%);
        z-index: 20;
        img{
            height: 50px;
            top: 0;
            margin-top: -15px;
            transition: 2s;
        }
    }
    &__Controls{
        display: flex;
        /* pirwsza poprawka
        width: 300px;
        margin: 2rem 0 0 1.4rem; */
            width: 240px;
            position: absolute;
            top: -55px;
            &.sLeft{
                left: 0;
                .carousel-control-prev{
                    margin-right: 50px;
                    margin-left: 50px;
                }
            }
            &.sRight{
                right: 0;
                .carousel-control-next{
                    margin-left: 50px;
                    margin-left: 50px;
                }
            }
    }
    .container-fluid {
        background-color: #E9E9EA!important;
    }
    h2.mainSection__title {
        font-family: 'caviar_dreamsbold';
        /* font-size: 30px; */
        font-size: 24px;
        line-height: 44px;
        margin-bottom: 1rem;;
        text-transform: uppercase;
    }
    &__text {
        color: #4F4F4F;
        /* font-size: 14px; */
        line-height: 21px;
        font-family: 'Poppins', sans-serif;
        padding-right: 2rem;
        @media (max-width: 1400px) {
            padding-right: 1rem;
        }
        @media (max-width: 1200px) {
            padding-right: 0;
        }
        p{            
            @include MainTextSize;
        }
    }
    &__textWrapper {
        margin: 10rem 0 0 2rem;
        @media (max-width: 991px) {
            margin: 2rem 0 0 0;
        }
    }
    &__module {
        &.fistModule{
            padding-top: 4rem;
        }
        padding: 2rem 0;
    }
}


body.page,
body.modul_oferty_specjal-template-default,
body.single,
body.single-modul_oferty_specjal {
    .carousel-control-next, .carousel-control-prev {
        position: relative;
        top: unset;
        display: block;
        /* width: 50%; */
        /* margin-right: 50px; */
        img {
            width: 35px;
        }
        @media (max-width: 1400px) {
        }
        @media (max-width: 1300px) {
            margin: 2rem 0 0 0;
        }    
    }
    #next-przyjecia-okolicznosciowe.carousel-control-next, 
    #prev-przyjecia-okolicznosciowe.carousel-control-prev {    
        top: 86%;
    }
    .carousel-indicators li {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        margin-right: 2px;
        margin-left: 2px;
        background-color: #b9b2b2;
    }
    .button {
        font-size: 13px;
    }
    .mainSection {
        .logoCzarne {            
            height: 45px;
            width: auto;
        }
        &__Controls{
            /* display: flex;
            width: 240px; */
            /* margin: 0 auto 4rem auto;    */  
            width: 240px;
            position: absolute;
            top: -55px;
            &.sLeft{
                left: 0;
                .carousel-control-prev{
                    margin-right: 50px;
                    margin-left: 50px;
                }
            }
            &.sRight{
                right: 0;
                margin-right: -75px;
                .carousel-control-next{
                    margin-left: 50px;
                }
            }
        }
        &__textWrapper {
            margin: 0rem 0 0 2rem;
            @media (max-width: 1400px) {
                margin: 2rem 0 0 5rem;
            }
            @media (max-width: 1300px) {
                margin: 1rem 0 0 5rem;
            }
            @media (max-width: 1200px) {
                margin: 0 0 0 5rem;
            }
            @media (max-width: 991px) {
                margin: 0;
            }
        }
        &__icon {
            margin: 0 auto 1.5rem 3rem;
            @media (max-width: 991px) {
                margin: 1.5rem 0;
            }
        }
        &__ItemFlex{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1rem;
        }
    }
    .order-lg-first .mainSection__textWrapper {
        margin: 0;
    }
    .mainSection__module--second {
        padding-bottom: 5rem;
    }
    .mainSection__logo {
        padding-top: 2rem;
        padding-bottom: 1rem;
    }
    #next-wielowymiarowe.carousel-control-next {  
        top: 86%;  
        left: -56%!important;
        @media (max-width: 1300px) {
            left: -56%!important;
        }
        @media (max-width: 1200px) {
            left: -56%!important;
        }
    }
    #prev-wielowymiarowe.carousel-control-prev { 
        top: 86%;   
        left: -64%!important;
        @media (max-width: 1300px) {
            left: -64%!important;
        }
        @media (max-width: 1200px) {
            left: -64%!important;
        }
    }
}