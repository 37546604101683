.liczby {
    .container, .container-fluid {
        background-color: #E9E9EA!important;
    }
    h2.liczby__title {
        font-family: 'caviar_dreamsbold';
        font-size: 45px;
        line-height: 44px;
        margin: 2rem 0 1rem;
        text-transform: uppercase;
        text-align: center;
        &--small {
            font-size: 30px;
            @media (max-width: 991px) {
                font-size: 20px!important;
                margin-top: 1rem!important;
            }
            @media (max-width: 575px) {
                margin-top: .5rem!important;
            }
        }
        @media (max-width: 991px) {
            font-size: 30px;
        }
        @media (max-width: 575px) {
            font-size: 24px;
            margin-bottom: .5rem;
        }
    }
    &__img {
        text-align: center;
    }
    &__liczba {
        font-family: 'Poppins'!important;
        font-size: 30px;
        margin-bottom: .5rem;
        text-align: center!important;
        @media (max-width: 991px) {
            font-size: 20px;
        }
        @media (max-width: 575px) {
            font-size: 16px;
        }
    }
    &__podpis {
        font-family: 'Poppins'!important;
        font-size: 18px;
        text-align: center!important;
        text-transform: uppercase!important;
        margin-bottom: 3rem;
        @media (max-width: 991px) {
            font-size: 16px;
        }
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }
    &__icon {
        margin: 2rem auto 1rem auto!important;
    }
}