@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400&display=swap");
html {
  box-sizing: border-box;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

.body {
  font-family: 'caviar_dreamsbold';
}

.container-fluid {
  padding: 0;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.mainContainer {
  max-width: 1410px;
  margin: 0 auto;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mainContainer {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .mainContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.bold {
  font-size: 14px;
}

@media (min-width: 576px) {
  .bold {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .bold {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .bold {
    font-size: 52px;
  }
}

a {
  color: #000;
}

/* BACKGROUND */
/* COLORS */
/*  others */
p {
  font-size: 13px;
  text-align: justify;
}

@media (min-width: 768px) {
  p {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  p {
    font-size: 13px;
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: relative;
}

.psuedo-background-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 460px) {
  .subtitleSection {
    font-size: 18px;
  }
}

@media (min-width: 576px) {
  .subtitleSection {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .subtitleSection {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .subtitleSection {
    font-size: 24px;
  }
}

@media (min-width: 1410px) {
  .subtitleSection {
    font-size: 28px;
  }
}

.subtitleSection strong, .subtitleSection--bold {
  font-size: 14px;
}

@media (min-width: 576px) {
  .subtitleSection strong, .subtitleSection--bold {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .subtitleSection strong, .subtitleSection--bold {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .subtitleSection strong, .subtitleSection--bold {
    font-size: 52px;
  }
}

.subtitleSection--small {
  font-size: 26px;
}

@media (min-width: 460px) {
  .subtitleSection--small {
    font-size: 28px;
  }
}

@media (min-width: 576px) {
  .subtitleSection--small {
    font-size: 30px;
  }
}

@media (min-width: 768px) {
  .subtitleSection--small {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .subtitleSection--small {
    font-size: 34px;
  }
}

@media (min-width: 1410px) {
  .subtitleSection--small {
    font-size: 36px;
  }
}

.subtitleSection--xsmall {
  font-size: 16px;
}

@media (min-width: 460px) {
  .subtitleSection--xsmall {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .subtitleSection--xsmall {
    font-size: 18px;
  }
}

.titleSection {
  font-size: 14px;
}

@media (min-width: 576px) {
  .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .titleSection {
    font-size: 52px;
  }
}

@media (min-width: 460px) {
  .titleSection {
    font-size: 33px;
  }
}

@media (min-width: 576px) {
  .titleSection {
    font-size: 36px;
  }
}

@media (min-width: 768px) {
  .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .titleSection {
    font-size: 40px;
  }
}

@media (min-width: 1410px) {
  .titleSection {
    font-size: 52px;
  }
}

.titleSection strong, .titleSection--bold {
  font-size: 14px;
}

@media (min-width: 576px) {
  .titleSection strong, .titleSection--bold {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .titleSection strong, .titleSection--bold {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .titleSection strong, .titleSection--bold {
    font-size: 52px;
  }
}

.titleSection--small {
  font-size: 26px;
}

@media (min-width: 460px) {
  .titleSection--small {
    font-size: 28px;
  }
}

@media (min-width: 576px) {
  .titleSection--small {
    font-size: 30px;
  }
}

@media (min-width: 768px) {
  .titleSection--small {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .titleSection--small {
    font-size: 34px;
  }
}

@media (min-width: 1410px) {
  .titleSection--small {
    font-size: 36px;
  }
}

.titleSection--xsmall {
  font-size: 20px;
}

@media (min-width: 460px) {
  .titleSection--xsmall {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .titleSection--xsmall {
    font-size: 24px;
  }
}

body {
  padding-top: 0 !important;
}

.hidden {
  display: none !important;
}

.show {
  display: block !important;
}

.novisible {
  display: none !important;
}

.showvisible {
  display: block !important;
  padding: 0 !important;
}

.icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  fill: #ffffff;
}

.centerOfScreen {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* Yep! */
  width: 48%;
  height: 59%;
}

body {
  font-family: 'Lato', sans-serif;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 10, 2020 */
@font-face {
  font-family: 'caviar_dreamsbold';
  src: url("../../fonts/caviardreams_bold-webfont.eot");
  src: url("../../fonts/caviardreams_bold-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/caviardreams_bold-webfont.woff2") format("woff2"), url("../../fonts/caviardreams_bold-webfont.woff") format("woff"), url("../../fonts/caviardreams_bold-webfont.ttf") format("truetype"), url("../../fonts/caviardreams_bold-webfont.svg#caviar_dreamsbold") format("svg");
  font-weight: normal;
  font-style: normal;
}

.yellowBigBtn,
.blueBigBtn {
  border-radius: 0;
  padding: 8px;
  font-size: 12px;
  width: 100%;
  max-width: 140px;
  margin-bottom: 2px;
  margin-top: 2px;
  text-transform: uppercase;
  display: none;
}

@media (min-width: 350px) {
  .yellowBigBtn,
  .blueBigBtn {
    font-size: 13px;
  }
}

.button {
  border: 1px solid #4F4F4F;
  padding: 1rem;
  text-transform: uppercase;
  text-align: center;
  max-width: 200px;
  cursor: pointer;
}

.button:hover {
  text-decoration: none;
  color: #000;
}

.buttonWrapper {
  margin: 2rem 0 0 0;
}

.film {
  padding-top: 1rem;
  padding-bottom: 3rem;
  background-color: #e9e9ea !important;
}

.film__tytul {
  font-size: 30px;
  text-transform: uppercase;
  font-family: 'caviar_dreamsbold';
  text-align: center;
  margin: 1rem 0 2rem 0;
}

.film .container-fluid {
  background-color: #E9E9EA;
  padding: 0;
}

.film .mainContainer {
  background-repeat: no-repeat;
}

.film .mainContainer::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #685858;
  opacity: 0.4;
  z-index: 1;
}

.film__film {
  display: none;
}

.film__inner {
  position: relative;
  /* display: table-cell; */
  vertical-align: middle;
  height: 650px;
  z-index: 2;
}

@media (max-width: 500px) {
  .film__inner {
    height: 350px;
  }
}

.film__content {
  max-width: 1000px;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
}

.film__content h2 {
  font-size: 4.33333em;
  text-transform: capitalize;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
}

@media (max-width: 500px) {
  .film__content h2 {
    font-size: 3rem;
  }
}

.film__content p {
  font-size: 1.533rem;
  font-style: italic;
  font-weight: 700;
  color: #fff;
}

.film .video-control__play {
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;
  opacity: 1;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  position: relative;
  margin: 0 auto;
  padding: 5px;
}

.film .video-control__play .icon {
  opacity: 1;
}

.video-is-playing .video--image_with_play, .video-is-paused .video--image_with_play {
  display: block;
  visibility: visible;
  opacity: 1;
}

.video--image_with_play {
  /* display: none;
	opacity: 0;
	visibility: none; */
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in;
}

/* .video {
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
} */
.realizacje .container-fluid {
  background-color: #E9E9EA;
  padding: 5rem 0 0;
}

.realizacje div.carousel-item {
  opacity: 0.48;
  display: flex;
}

.realizacje div.carousel-item.active {
  opacity: 1;
  text-align: center;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

.realizacje div.carousel-item.active img {
  text-align: center;
  margin: 0 auto;
}

.realizacje div.owl-item.cloned, .realizacje div.owl-item.active, .realizacje div.owl-item, .realizacje .owl-item {
  max-width: 930px !important;
  max-height: 617px !important;
}

.realizacje__tytul {
  font-size: 30px;
  text-transform: uppercase;
  font-family: 'caviar_dreamsbold';
  text-align: center;
  margin: 1rem 0 2rem 0;
}

.realizacje .owl-item:not(.center) {
  opacity: 0.48 !important;
}

.realizacje .owl-theme .owl-dots, .realizacje .owl-theme .owl-nav {
  text-align: left;
  display: block !important;
}

.realizacje .owl-carousel .owl-nav {
  text-align: center;
  display: unset;
}

.realizacje .owl-carousel .owl-nav button {
  height: 37px;
  width: 37px;
}

.realizacje .owl-carousel .owl-nav button span {
  display: none;
}

.realizacje .owl-carousel .owl-nav button.owl-prev {
  background-image: url("../../dist/images/strzalka-lewa.svg");
}

.realizacje .owl-carousel .owl-nav button.owl-next {
  background-image: url("../../dist/images/strzalka-prawa.svg");
}

.realizacje__naszeHale {
  padding: 0 0 3rem 0;
  background: none;
}

.realizacje__naszeHale__title {
  font-family: 'caviar_dreamsbold';
  font-size: 30px;
  line-height: 44px;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
}

.realizacje__naszeHale__Text {
  padding: 0;
  color: #4f4f4f;
  font-size: 14px;
  line-height: 21px;
  font-family: Poppins,sans-serif;
  margin: 0 -15px;
}

.realizacje .container p {
  margin: 1rem 0;
}

.realizacje .item .textBox {
  position: absolute;
  bottom: 10%;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 3px 3px 7px #000;
  font-size: 18px;
}

.carouselSprzet {
  background-color: #D6D6D6;
}

.carouselSprzet__slide {
  text-align: right;
}

.carouselSprzet__subheader {
  font-family: 'Poppins';
  font-size: 21px;
  font-weight: 400;
}

@media (max-width: 991px) {
  .carouselSprzet__subheader {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .carouselSprzet__subheader {
    font-size: 12px;
  }
}

.carouselSprzet__text {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 991px) {
  .carouselSprzet__text {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .carouselSprzet__text {
    font-size: 10px;
  }
}

.carouselSprzet__cyfra {
  font-family: 'caviar_dreamsbold';
  font-size: 20px;
  font-weight: 500;
  margin: auto;
  text-align: center;
}

.carouselSprzet ol.carousel-indicators {
  display: block;
  top: 0;
  width: 50%;
  padding: 5rem 2rem 5rem 15rem;
  background-color: #D6D6D6;
  margin: 0;
}

@media (max-width: 1350px) {
  .carouselSprzet ol.carousel-indicators {
    padding: 2rem 2rem 2rem 10rem;
  }
}

@media (max-width: 1350px) {
  .carouselSprzet ol.carousel-indicators {
    padding: 1rem 2rem 1rem 2rem;
  }
}

.carouselSprzet ol.carousel-indicators li {
  width: auto !important;
  height: auto !important;
  background-color: transparent !important;
  text-indent: 0 !important;
  border-radius: 0%;
  opacity: 1;
}

.carouselSprzet ol.carousel-indicators li .active {
  font-weight: 600;
}

.carouselSprzet ol.carousel-indicators .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #A5A89D;
  margin: .6rem 1rem 0 0;
  text-align: center;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .carouselSprzet ol.carousel-indicators .circle {
    margin-top: 0;
  }
}

.carouselSprzet ol.carousel-indicators li.active {
  font-weight: 600 !important;
}

.carouselSprzet ol.carousel-indicators li.active .circle {
  background-color: #7F7F7F;
  color: white;
}

.carouselSprzet ol.carousel-indicators li.active .carouselSprzet__subheader {
  font-weight: 600;
  color: black;
  text-align: left;
}

.carouselSprzet ol.carousel-indicators li.active .carouselSprzet__text {
  font-weight: 600;
  color: black;
  text-align: left;
}

.carouselSprzet .carousel-indicators .active {
  font-weight: 600;
}

.header {
  /* 
    #SLiderTop1:hover h2.header__title, 
    #SLiderTop2:hover h2.header__title, 
    #SLiderTop3:hover h2.header__title {        
        font-size: 4.5rem;
        transition: 1s
    } */
}

.header__slide {
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-position: center;
  padding: 0;
}

@media (max-width: 991px) {
  .header__slide {
    height: 50vh;
  }
}

.header__gradient {
  background-image: linear-gradient(rgba(0, 0, 0, 0), black);
  position: relative;
  z-index: 10;
}

.header h2.header__title {
  color: #fff !important;
  text-transform: uppercase !important;
  font-family: 'caviar_dreamsbold';
  font-size: 4rem;
  line-height: 72px;
  text-align: center;
  position: relative;
  z-index: 10;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 
        &:hover {
            font-size: 4.5rem;
            transition: 1s
        } */
}

@media (max-width: 1500px) and (min-width: 992px) {
  .header h2.header__title {
    font-size: 40px;
  }
}

.header h2.header__title__gradient {
  background-image: linear-gradient(rgba(0, 0, 0, 0), black);
  position: relative;
  z-index: 10;
}

.header #slide1 {
  background-image: url("../../dist/images/slide1.jpg");
  background-size: 260%;
  background-position: -13rem 70%;
}

@media (max-width: 991px) {
  .header #slide1 {
    height: 50vh;
  }
}

.header #slide1:hover {
  background-size: 400% !important;
  transition: 2s;
}

.header #slide2 {
  background-image: url("../../dist/images/slide2.jpg");
  background-size: 260%;
}

.header #slide2:hover {
  background-size: 400% !important;
  transition: 2s;
}

.header #slide3 {
  background-image: url("../../dist/images/slide3.jpg");
  background-size: 260%;
}

.header #slide3:hover {
  background-size: 400% !important;
  transition: 2s;
}

.header__slideContainer {
  padding: 0;
  height: 100vh;
  cursor: pointer;
}

@media (max-width: 991px) {
  .header__slideContainer {
    height: 50vh;
  }
}

.header__slideContainer:hover h2.header__title {
  font-size: 4.5rem;
  transition: 1s;
}

.header__Logo {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -15%);
  z-index: 20;
}

.header__Logo__main {
  top: 15%;
}

.header__Logo__main__img {
  height: 164px;
}

@media (max-width: 991px) {
  .header__Logo__main__img {
    display: none;
  }
}

.header__Logo__fixed {
  display: none;
  top: 0;
  margin-top: 10px;
}

.header__Logo__fixed__img {
  height: 45px;
  width: auto;
  text-align: center;
  margin: 4px auto 0 auto;
}

.header__fixed {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 0;
  padding: 10px 0;
}

.header__fixed .nav-item.active {
  border-bottom: 1px solid #000;
}

.header__fixed .nav-link {
  color: #000 !important;
}

.header__fixed__hidden {
  display: none;
}

.header__fixed__showRestMenu {
  display: block !important;
}

.header__fixed .header__logoSticky {
  display: block !important;
}

.header__logoSticky {
  display: none;
}

/** header dla podstron **/
body.page .header__slideContainer,
body.single .header__slideContainer,
body.single-modul_oferty_specjal .header__slideContainer {
  padding: 0;
  height: 100vh;
  cursor: auto;
}

@media (max-width: 991px) {
  body.page .header__slideContainer,
  body.single .header__slideContainer,
  body.single-modul_oferty_specjal .header__slideContainer {
    height: 50vh;
  }
}

body.page .header__slideContainer:hover h2.header__title,
body.single .header__slideContainer:hover h2.header__title,
body.single-modul_oferty_specjal .header__slideContainer:hover h2.header__title {
  font-size: 4.5rem;
  transition: 1s;
}

body.page .header__slide,
body.single .header__slide,
body.single-modul_oferty_specjal .header__slide {
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-position: center;
  padding: 0;
}

@media (max-width: 991px) {
  body.page .header__slide,
  body.single .header__slide,
  body.single-modul_oferty_specjal .header__slide {
    height: 50vh;
  }
}

body.page .header__menuItem,
body.single .header__menuItem,
body.single-modul_oferty_specjal .header__menuItem {
  text-transform: uppercase !important;
  font-family: 'caviar_dreamsbold';
  font-size: 30px;
  line-height: 72px;
  text-align: center;
  position: absolute;
  z-index: 10;
  top: 0;
  text-align: center;
}

body.page .header__menuItem a,
body.single .header__menuItem a,
body.single-modul_oferty_specjal .header__menuItem a {
  color: white;
  text-transform: uppercase !important;
  /* font-family: 'caviar_dreamsbold'; */
  font-family: 'lato';
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

body.page .header__menuItem a:hover,
body.single .header__menuItem a:hover,
body.single-modul_oferty_specjal .header__menuItem a:hover {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  text-decoration: none;
}

body.page .header__menuItem--first,
body.single .header__menuItem--first,
body.single-modul_oferty_specjal .header__menuItem--first {
  left: 0%;
}

body.page .header__menuItem--second,
body.single .header__menuItem--second,
body.single-modul_oferty_specjal .header__menuItem--second {
  left: 33%;
}

body.page .header__menuItem--third,
body.single .header__menuItem--third,
body.single-modul_oferty_specjal .header__menuItem--third {
  left: 66%;
}

body.page .header__menuItem.active a,
body.single .header__menuItem.active a,
body.single-modul_oferty_specjal .header__menuItem.active a {
  color: #9E9E9E !important;
}

body.page .header h2.header__title:hover,
body.single .header h2.header__title:hover,
body.single-modul_oferty_specjal .header h2.header__title:hover {
  font-size: 60px;
}

body.page .header__socialMedia,
body.single .header__socialMedia,
body.single-modul_oferty_specjal .header__socialMedia {
  position: relative;
  z-index: 6;
  margin: -10rem 0 0 auto;
  float: right;
}

@media (max-width: 991px) {
  body.page .header__socialMedia,
  body.single .header__socialMedia,
  body.single-modul_oferty_specjal .header__socialMedia {
    margin: -50rem 0 0 auto;
  }
}

body.page .header__slide--slide1 {
  background-color: rgba(0, 0, 0, 0.2);
  background-image: url("../../dist/images/slide_ogolny.jpg");
  background-size: cover;
}

@media (max-width: 991px) {
  body.page .header__slide--slide1 {
    background-position: 0;
  }
}

body.page-template-page-restauracja .header__slide--slide1 {
  background-image: url("../../dist/images/slide1_restauracje.jpg") !important;
  background-size: cover;
}

@media (max-width: 991px) {
  body.page-template-page-restauracja .header__slide--slide1 {
    background-position: 0;
  }
}

body.page-template.page-template-page-minibrowar.page-template-page-minibrowar-php.page.page-id-38 .header__slide--slide1 {
  background-image: url("../../dist/images/slide2_minibrowar.jpg") !important;
  background-size: cover;
}

@media (max-width: 991px) {
  body.page-template.page-template-page-minibrowar.page-template-page-minibrowar-php.page.page-id-38 .header__slide--slide1 {
    background-position: 0;
  }
}

body.page-template.page-template-page-eventy.page-template-page-eventy-php.page.page-id-28 .header__slide--slide1 {
  background-image: url("../../dist/images/slide3_eventy.jpg") !important;
  background-size: cover;
}

@media (max-width: 991px) {
  body.page-template.page-template-page-eventy.page-template-page-eventy-php.page.page-id-28 .header__slide--slide1 {
    background-position: 0;
  }
}

body.modul_oferty_specjal-template-default .header__slide--slide1,
body.single .header__slide--slide1 {
  background-image: url("../../dist/images/slide4_oferty.jpg") !important;
  background-size: cover;
}

@media (max-width: 991px) {
  body.modul_oferty_specjal-template-default .header__slide--slide1,
  body.single .header__slide--slide1 {
    background-position: 0;
  }
}

body.page-template-page-onas .header__slide--slide1 {
  background-image: url("../../dist/images/slide_onas.jpg");
  background-size: cover;
}

@media (max-width: 991px) {
  body.page-template-page-onas .header__slide--slide1 {
    background-position: 0;
  }
}

body.page-template-page-galeria .header__slide--slide1 {
  background-image: url("../../dist/images/slide_galeria.jpg");
  background-size: cover;
}

@media (max-width: 991px) {
  body.page-template-page-galeria .header__slide--slide1 {
    background-position: 0;
  }
}

body.home .header__slideContainer {
  cursor: pointer;
}

section {
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.mainSection__fixed {
  position: fixed;
  top: 0;
  margin: 0;
  padding: 0;
  left: 50%;
  transform: translate(-50%, -15%);
  z-index: 20;
}

.mainSection__fixed img {
  height: 50px;
  top: 0;
  margin-top: -15px;
  transition: 2s;
}

.mainSection__Controls {
  display: flex;
  /* pirwsza poprawka
        width: 300px;
        margin: 2rem 0 0 1.4rem; */
  width: 240px;
  position: absolute;
  top: -55px;
}

.mainSection__Controls.sLeft {
  left: 0;
}

.mainSection__Controls.sLeft .carousel-control-prev {
  margin-right: 50px;
  margin-left: 50px;
}

.mainSection__Controls.sRight {
  right: 0;
}

.mainSection__Controls.sRight .carousel-control-next {
  margin-left: 50px;
  margin-left: 50px;
}

.mainSection .container-fluid {
  background-color: #E9E9EA !important;
}

.mainSection h2.mainSection__title {
  font-family: 'caviar_dreamsbold';
  /* font-size: 30px; */
  font-size: 24px;
  line-height: 44px;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.mainSection__text {
  color: #4F4F4F;
  /* font-size: 14px; */
  line-height: 21px;
  font-family: 'Poppins', sans-serif;
  padding-right: 2rem;
}

@media (max-width: 1400px) {
  .mainSection__text {
    padding-right: 1rem;
  }
}

@media (max-width: 1200px) {
  .mainSection__text {
    padding-right: 0;
  }
}

.mainSection__text p {
  font-size: 13px;
  text-align: justify;
}

.mainSection__textWrapper {
  margin: 10rem 0 0 2rem;
}

@media (max-width: 991px) {
  .mainSection__textWrapper {
    margin: 2rem 0 0 0;
  }
}

.mainSection__module {
  padding: 2rem 0;
}

.mainSection__module.fistModule {
  padding-top: 4rem;
}

body.page .carousel-control-next, body.page .carousel-control-prev,
body.modul_oferty_specjal-template-default .carousel-control-next,
body.modul_oferty_specjal-template-default .carousel-control-prev,
body.single .carousel-control-next,
body.single .carousel-control-prev,
body.single-modul_oferty_specjal .carousel-control-next,
body.single-modul_oferty_specjal .carousel-control-prev {
  position: relative;
  top: unset;
  display: block;
  /* width: 50%; */
  /* margin-right: 50px; */
}

body.page .carousel-control-next img, body.page .carousel-control-prev img,
body.modul_oferty_specjal-template-default .carousel-control-next img,
body.modul_oferty_specjal-template-default .carousel-control-prev img,
body.single .carousel-control-next img,
body.single .carousel-control-prev img,
body.single-modul_oferty_specjal .carousel-control-next img,
body.single-modul_oferty_specjal .carousel-control-prev img {
  width: 35px;
}

@media (max-width: 1300px) {
  body.page .carousel-control-next, body.page .carousel-control-prev,
  body.modul_oferty_specjal-template-default .carousel-control-next,
  body.modul_oferty_specjal-template-default .carousel-control-prev,
  body.single .carousel-control-next,
  body.single .carousel-control-prev,
  body.single-modul_oferty_specjal .carousel-control-next,
  body.single-modul_oferty_specjal .carousel-control-prev {
    margin: 2rem 0 0 0;
  }
}

body.page #next-przyjecia-okolicznosciowe.carousel-control-next,
body.page #prev-przyjecia-okolicznosciowe.carousel-control-prev,
body.modul_oferty_specjal-template-default #next-przyjecia-okolicznosciowe.carousel-control-next,
body.modul_oferty_specjal-template-default #prev-przyjecia-okolicznosciowe.carousel-control-prev,
body.single #next-przyjecia-okolicznosciowe.carousel-control-next,
body.single #prev-przyjecia-okolicznosciowe.carousel-control-prev,
body.single-modul_oferty_specjal #next-przyjecia-okolicznosciowe.carousel-control-next,
body.single-modul_oferty_specjal #prev-przyjecia-okolicznosciowe.carousel-control-prev {
  top: 86%;
}

body.page .carousel-indicators li,
body.modul_oferty_specjal-template-default .carousel-indicators li,
body.single .carousel-indicators li,
body.single-modul_oferty_specjal .carousel-indicators li {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 2px;
  margin-left: 2px;
  background-color: #b9b2b2;
}

body.page .button,
body.modul_oferty_specjal-template-default .button,
body.single .button,
body.single-modul_oferty_specjal .button {
  font-size: 13px;
}

body.page .mainSection .logoCzarne,
body.modul_oferty_specjal-template-default .mainSection .logoCzarne,
body.single .mainSection .logoCzarne,
body.single-modul_oferty_specjal .mainSection .logoCzarne {
  height: 45px;
  width: auto;
}

body.page .mainSection__Controls,
body.modul_oferty_specjal-template-default .mainSection__Controls,
body.single .mainSection__Controls,
body.single-modul_oferty_specjal .mainSection__Controls {
  /* display: flex;
            width: 240px; */
  /* margin: 0 auto 4rem auto;    */
  width: 240px;
  position: absolute;
  top: -55px;
}

body.page .mainSection__Controls.sLeft,
body.modul_oferty_specjal-template-default .mainSection__Controls.sLeft,
body.single .mainSection__Controls.sLeft,
body.single-modul_oferty_specjal .mainSection__Controls.sLeft {
  left: 0;
}

body.page .mainSection__Controls.sLeft .carousel-control-prev,
body.modul_oferty_specjal-template-default .mainSection__Controls.sLeft .carousel-control-prev,
body.single .mainSection__Controls.sLeft .carousel-control-prev,
body.single-modul_oferty_specjal .mainSection__Controls.sLeft .carousel-control-prev {
  margin-right: 50px;
  margin-left: 50px;
}

body.page .mainSection__Controls.sRight,
body.modul_oferty_specjal-template-default .mainSection__Controls.sRight,
body.single .mainSection__Controls.sRight,
body.single-modul_oferty_specjal .mainSection__Controls.sRight {
  right: 0;
  margin-right: -75px;
}

body.page .mainSection__Controls.sRight .carousel-control-next,
body.modul_oferty_specjal-template-default .mainSection__Controls.sRight .carousel-control-next,
body.single .mainSection__Controls.sRight .carousel-control-next,
body.single-modul_oferty_specjal .mainSection__Controls.sRight .carousel-control-next {
  margin-left: 50px;
}

body.page .mainSection__textWrapper,
body.modul_oferty_specjal-template-default .mainSection__textWrapper,
body.single .mainSection__textWrapper,
body.single-modul_oferty_specjal .mainSection__textWrapper {
  margin: 0rem 0 0 2rem;
}

@media (max-width: 1400px) {
  body.page .mainSection__textWrapper,
  body.modul_oferty_specjal-template-default .mainSection__textWrapper,
  body.single .mainSection__textWrapper,
  body.single-modul_oferty_specjal .mainSection__textWrapper {
    margin: 2rem 0 0 5rem;
  }
}

@media (max-width: 1300px) {
  body.page .mainSection__textWrapper,
  body.modul_oferty_specjal-template-default .mainSection__textWrapper,
  body.single .mainSection__textWrapper,
  body.single-modul_oferty_specjal .mainSection__textWrapper {
    margin: 1rem 0 0 5rem;
  }
}

@media (max-width: 1200px) {
  body.page .mainSection__textWrapper,
  body.modul_oferty_specjal-template-default .mainSection__textWrapper,
  body.single .mainSection__textWrapper,
  body.single-modul_oferty_specjal .mainSection__textWrapper {
    margin: 0 0 0 5rem;
  }
}

@media (max-width: 991px) {
  body.page .mainSection__textWrapper,
  body.modul_oferty_specjal-template-default .mainSection__textWrapper,
  body.single .mainSection__textWrapper,
  body.single-modul_oferty_specjal .mainSection__textWrapper {
    margin: 0;
  }
}

body.page .mainSection__icon,
body.modul_oferty_specjal-template-default .mainSection__icon,
body.single .mainSection__icon,
body.single-modul_oferty_specjal .mainSection__icon {
  margin: 0 auto 1.5rem 3rem;
}

@media (max-width: 991px) {
  body.page .mainSection__icon,
  body.modul_oferty_specjal-template-default .mainSection__icon,
  body.single .mainSection__icon,
  body.single-modul_oferty_specjal .mainSection__icon {
    margin: 1.5rem 0;
  }
}

body.page .mainSection__ItemFlex,
body.modul_oferty_specjal-template-default .mainSection__ItemFlex,
body.single .mainSection__ItemFlex,
body.single-modul_oferty_specjal .mainSection__ItemFlex {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

body.page .order-lg-first .mainSection__textWrapper,
body.modul_oferty_specjal-template-default .order-lg-first .mainSection__textWrapper,
body.single .order-lg-first .mainSection__textWrapper,
body.single-modul_oferty_specjal .order-lg-first .mainSection__textWrapper {
  margin: 0;
}

body.page .mainSection__module--second,
body.modul_oferty_specjal-template-default .mainSection__module--second,
body.single .mainSection__module--second,
body.single-modul_oferty_specjal .mainSection__module--second {
  padding-bottom: 5rem;
}

body.page .mainSection__logo,
body.modul_oferty_specjal-template-default .mainSection__logo,
body.single .mainSection__logo,
body.single-modul_oferty_specjal .mainSection__logo {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

body.page #next-wielowymiarowe.carousel-control-next,
body.modul_oferty_specjal-template-default #next-wielowymiarowe.carousel-control-next,
body.single #next-wielowymiarowe.carousel-control-next,
body.single-modul_oferty_specjal #next-wielowymiarowe.carousel-control-next {
  top: 86%;
  left: -56% !important;
}

@media (max-width: 1300px) {
  body.page #next-wielowymiarowe.carousel-control-next,
  body.modul_oferty_specjal-template-default #next-wielowymiarowe.carousel-control-next,
  body.single #next-wielowymiarowe.carousel-control-next,
  body.single-modul_oferty_specjal #next-wielowymiarowe.carousel-control-next {
    left: -56% !important;
  }
}

@media (max-width: 1200px) {
  body.page #next-wielowymiarowe.carousel-control-next,
  body.modul_oferty_specjal-template-default #next-wielowymiarowe.carousel-control-next,
  body.single #next-wielowymiarowe.carousel-control-next,
  body.single-modul_oferty_specjal #next-wielowymiarowe.carousel-control-next {
    left: -56% !important;
  }
}

body.page #prev-wielowymiarowe.carousel-control-prev,
body.modul_oferty_specjal-template-default #prev-wielowymiarowe.carousel-control-prev,
body.single #prev-wielowymiarowe.carousel-control-prev,
body.single-modul_oferty_specjal #prev-wielowymiarowe.carousel-control-prev {
  top: 86%;
  left: -64% !important;
}

@media (max-width: 1300px) {
  body.page #prev-wielowymiarowe.carousel-control-prev,
  body.modul_oferty_specjal-template-default #prev-wielowymiarowe.carousel-control-prev,
  body.single #prev-wielowymiarowe.carousel-control-prev,
  body.single-modul_oferty_specjal #prev-wielowymiarowe.carousel-control-prev {
    left: -64% !important;
  }
}

@media (max-width: 1200px) {
  body.page #prev-wielowymiarowe.carousel-control-prev,
  body.modul_oferty_specjal-template-default #prev-wielowymiarowe.carousel-control-prev,
  body.single #prev-wielowymiarowe.carousel-control-prev,
  body.single-modul_oferty_specjal #prev-wielowymiarowe.carousel-control-prev {
    left: -64% !important;
  }
}

.specialOffers .container-fluid {
  background-color: #E9E9EA;
  padding: 3rem 0;
}

.specialOffers h2.specialOffers__title {
  font-family: 'caviar_dreamsbold';
  font-size: 30px;
  line-height: 44px;
  margin-bottom: 1rem;
  text-transform: uppercase;
  text-align: center;
}

.specialOffers h3.specialOffers__subheader {
  font-family: 'caviar_dreamsbold';
  /* font-size: 24px; */
  font-size: 22px;
  line-height: 44px;
  margin: 1rem 0;
  text-transform: uppercase;
  text-align: center;
}

.specialOffers__text {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  /* font-size: 14px; */
  line-height: 21px;
  color: #4F4F4F;
  font-size: 13px;
  text-align: justify;
}

@media (max-width: 991px) {
  .specialOffers__module {
    margin-top: 4rem;
  }
}

.liczby .container, .liczby .container-fluid {
  background-color: #E9E9EA !important;
}

.liczby h2.liczby__title {
  font-family: 'caviar_dreamsbold';
  font-size: 45px;
  line-height: 44px;
  margin: 2rem 0 1rem;
  text-transform: uppercase;
  text-align: center;
}

.liczby h2.liczby__title--small {
  font-size: 30px;
}

@media (max-width: 991px) {
  .liczby h2.liczby__title--small {
    font-size: 20px !important;
    margin-top: 1rem !important;
  }
}

@media (max-width: 575px) {
  .liczby h2.liczby__title--small {
    margin-top: 0.5rem !important;
  }
}

@media (max-width: 991px) {
  .liczby h2.liczby__title {
    font-size: 30px;
  }
}

@media (max-width: 575px) {
  .liczby h2.liczby__title {
    font-size: 24px;
    margin-bottom: .5rem;
  }
}

.liczby__img {
  text-align: center;
}

.liczby__liczba {
  font-family: "Poppins" !important;
  font-size: 30px;
  margin-bottom: .5rem;
  text-align: center !important;
}

@media (max-width: 991px) {
  .liczby__liczba {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .liczby__liczba {
    font-size: 16px;
  }
}

.liczby__podpis {
  font-family: "Poppins" !important;
  font-size: 18px;
  text-align: center !important;
  text-transform: uppercase !important;
  margin-bottom: 3rem;
}

@media (max-width: 991px) {
  .liczby__podpis {
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .liczby__podpis {
    font-size: 12px;
  }
}

.liczby__icon {
  margin: 2rem auto 1rem auto !important;
}

#map {
  width: 100%;
}

.footer {
  margin-bottom: -3rem;
}

.footer .menu-item .nav-link {
  color: black !important;
  text-transform: lowercase !important;
  font-family: 'Poppins';
  font-size: 15px;
  padding: 0;
}

.footer .menu-item .nav-link::after {
  content: "|";
  font-weight: 500;
  margin: 0 1rem;
}

@media (max-width: 991px) {
  .footer .menu-item .nav-link::after {
    display: none;
  }
}

.footer .menu-item-68 .nav-link::after {
  content: "";
}

.footer__firstSection {
  background-color: #F5F5F5;
  padding: 5rem 15px;
}

.footer__secondSection {
  background-color: #E9E9EA;
  padding: 3rem 0 0 0;
}

.footer__copyPadding {
  padding-left: 15px;
  padding-right: 15px;
}

.footer__noPadding {
  padding-left: 0;
  padding-right: 0;
}

.footer__daneKontaktowe {
  margin: auto;
}

@media (max-width: 991px) {
  .footer__daneKontaktowe {
    margin: 3rem auto;
  }
}

@media (max-width: 991px) {
  .footer__form {
    margin: 4rem auto 0 auto;
  }
}

.footer ul#menu-menu-footer-pl.footer__Top__navFooter {
  display: flex;
  text-align: center;
}

@media (max-width: 991px) {
  .footer ul#menu-menu-footer-pl.footer__Top__navFooter {
    display: block;
    text-align: center;
  }
}

.footer__line {
  border-top: 1px solid black;
  height: 1px;
  margin: 1rem 0;
}

.footer__tuJestesmy {
  font-size: 24px;
  line-height: 44px;
  color: #000000;
  font-family: 'caviar_dreamsbold';
  text-align: center;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.footer__kontakt {
  font-size: 15px;
  line-height: 25px;
  color: #000000;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.footer__kontakt p {
  text-align: center;
  font-size: 15px;
}

.footer__socialMedia {
  padding-bottom: 2rem;
}

.footer h3.footer__formHeader {
  font-size: 30px;
  line-height: 44px;
  color: black;
  font-family: 'caviar_dreamsbold';
  text-align: center;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.footer input.wpcf7-form-control.wpcf7-submit {
  display: block;
  text-align: center;
  font-size: 18px;
  line-height: 44px;
  color: white;
  background-color: #343434;
  font-family: 'caviar_dreamsbold';
  float: right;
  text-transform: uppercase;
  padding: 0 1rem;
}

.footer input#form_email, .footer input#form_name {
  border: 0;
  border-bottom: 1px solid #343434;
  background-color: #F5F5F5;
  width: 100%;
  font-family: 'Poppins';
  font-weight: 300;
  font-size: 14px;
}

.footer input#form_email, .footer textarea#form_text {
  margin-top: 3rem;
  background-color: #F5F5F5;
  width: 100%;
  font-family: 'Poppins';
  font-weight: 300;
  font-size: 14px;
}

.footer .map {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  width: 100%;
}

.footer__mapContainer {
  text-align: center;
}

.footer .wpcf7 form.failed .wpcf7-response-output, .footer .wpcf7 form.aborted .wpcf7-response-output, .footer .wpcf7 form .wpcf7-response-output {
  margin-top: 4rem !important;
}

.footer span.wpcf7-list-item {
  margin: 0 !important;
}

.footer .wpcf7-list-item-label {
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  color: #4F4F4F;
  padding-left: .5rem;
}

.footer__copy {
  font-size: 12px;
  line-height: 14.4px;
  color: #000000;
  font-family: 'Lato';
}

.footer__tripIcon img {
  margin-top: .4rem;
}

.footer__logo {
  margin: 0 auto 2rem auto;
  text-align: center;
}

.socialFixed {
  width: 30px;
  height: 250px;
  position: fixed;
  top: 35%;
  right: 0;
  display: none;
  z-index: 30;
}

@media (min-width: 576px) {
  .socialFixed {
    display: block;
  }
}

.socialFixed .item {
  margin-top: 5px;
  padding-top: 0;
}

#cookie-msg {
  padding: 12px !important;
  background-color: blue !important;
  z-index: 100;
}

#cookie-msg .msg {
  font-size: 12px;
}

@media (min-width: 576px) {
  #cookie-msg .msg {
    font-size: 14px;
  }
}

#cookie-msg a {
  color: yellow !important;
}

#cookie-msg .btn-aceptar {
  background-color: yellow !important;
  margin-left: 13px;
  font-size: 13px !important;
  color: blue !important;
  margin: 2px;
  display: inline-block;
}

.header .menu-item .nav-link {
  color: white;
  text-transform: uppercase !important;
  /* font-family: 'caviar_dreamsbold'; */
  font-family: 'lato';
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.header .menu-item .nav-link:hover {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.header__socialMedia__main {
  position: relative;
  z-index: 6;
  margin: -10rem 3rem 0 auto;
  float: right;
}

.header__socialMedia__main img {
  height: 18px;
  display: flex;
  position: relative;
  top: 50%;
  transform: translate(-50%);
}

.header__socialMedia__main img.header__icon2 {
  margin-left: 15px;
}

.header__socialMedia__main img.header__icon3 {
  margin-left: 10px;
}

@media (max-width: 991px) {
  .header__socialMedia__main {
    margin: -153rem 0 0 auto;
  }
}

.header__socialMedia__fixed {
  position: fixed;
  top: 0;
  width: 120px;
  margin: 0 10px 0 0;
  padding: 10px 0;
  text-align: right;
  z-index: 6;
  right: 0;
  display: none;
}

.header__socialMedia__fixed .header__socialMedia__icon {
  margin-right: 10px;
}

.header .navbar {
  position: relative;
  z-index: 6;
  margin: -10rem -15px 0 -15px;
}

@media (max-width: 991px) {
  .header .navbar {
    margin: -153rem auto 0 0;
  }
}

.header #navbarNavDropdown {
  padding-left: 3rem;
}

.bannery {
  background-color: #e9e9ea !important;
  padding-top: 2rem;
}

.bannery .container-fluid {
  background-color: #E9E9EA;
  padding: 0;
}

.bannery .container {
  background-color: #E9E9EA;
}

.bannery__additionalText {
  padding: 1rem 1rem;
}

.bannery__img {
  text-align: center;
}

.bannery__liczbaOsob {
  font-size: 25px;
  text-align: center;
  color: white;
  font-family: 'Poppins';
  font-weight: 500;
  margin-top: .5rem;
}

@media (max-width: 991px) {
  .bannery__liczbaOsob {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .bannery__liczbaOsob {
    font-size: 14px;
  }
}

@media (max-width: 575px) {
  .bannery__liczbaOsob {
    font-size: 10px;
  }
}

.bannery__text {
  position: relative;
  margin-top: -16rem;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 20%;
  max-width: 1410px;
}

@media (max-width: 1199px) {
  .bannery__text {
    transform: translate(-50%, -20%);
  }
}

@media (max-width: 991px) {
  .bannery__text {
    transform: translate(-50%, -18%);
    margin-top: -12rem;
  }
}

@media (max-width: 767px) {
  .bannery__text {
    margin-top: -9rem;
  }
}

@media (max-width: 575px) {
  .bannery__text {
    margin-top: -8rem;
    transform: translate(-50%, -30%);
  }
}

@media (max-width: 500px) {
  .bannery__text {
    margin-top: -8rem;
    transform: translate(-50%, -20%);
  }
}

@media (max-width: 400px) {
  .bannery__text {
    margin-top: -8rem;
    transform: translate(-50%, -10%);
  }
}

@media (max-width: 370px) {
  .bannery__text {
    margin-top: -6rem;
    transform: translate(-50%, -20%);
  }
}

.bannery__header {
  font-family: 'caviar_dreamsbold';
  font-size: 40px;
  text-transform: uppercase;
  color: white;
  text-align: center;
  font-weight: 700;
}

@media (max-width: 991px) {
  .bannery__header {
    font-size: 25px;
  }
}

@media (max-width: 767px) {
  .bannery__header {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .bannery__header {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .bannery__header {
    font-size: 14px;
  }
}

.bannery__rozmiar {
  font-family: 'Poppins';
  font-size: 24px;
  color: white;
  text-align: center;
  font-weight: 700;
  margin-bottom: 2rem;
}

@media (max-width: 991px) {
  .bannery__rozmiar {
    font-size: 16px;
    margin-bottom: 1rem;
  }
}

@media (max-width: 575px) {
  .bannery__rozmiar {
    font-size: 12px;
    margin-bottom: .5rem;
  }
}

@media (max-width: 400px) {
  .bannery__rozmiar {
    font-size: 12px;
    margin-bottom: 0;
  }
}

.bannery__smallText {
  font-family: 'Poppins';
  font-size: 12px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .bannery__smallText {
    font-size: 10px;
    margin-bottom: .5rem;
  }
}

@media (max-width: 400px) {
  .bannery__smallText {
    font-size: 8px;
    margin-bottom: .2rem;
    letter-spacing: 1px;
  }
}

.bannery__module {
  text-align: center;
  border-right: 1px solid white;
}

.bannery__module--last {
  border: none;
}

@media (max-width: 991px) {
  .bannery__module img {
    width: 40px;
  }
}

@media (max-width: 767px) {
  .bannery__module img {
    width: 30px;
  }
}

.bannery__naszeHale {
  padding: 0 0 3rem 0;
  background: none;
}

.bannery__naszeHale__title {
  font-family: 'caviar_dreamsbold';
  font-size: 30px;
  line-height: 44px;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
}

.bannery__naszeHale__Text {
  padding: 0;
  color: #4f4f4f;
  font-size: 14px;
  line-height: 21px;
  font-family: Poppins,sans-serif;
  margin: 0;
}

.burger {
  width: 45px;
  height: 30px;
  display: inline-block;
  margin-right: 10px;
  transition: .3s;
  margin-top: -11px;
  background-color: transparent;
  border: 0;
}

.burger:hover, .burger:active, .burger:focus {
  outline: 0;
}

@media (min-width: 992px) {
  .burger {
    display: none;
  }
}

.burger span {
  display: block;
  width: 35px;
  height: 2px;
  background-color: #fff;
  margin-top: 6px;
  transition: .3s;
}

.burger span:nth-child(2) {
  transition: .1s !important;
}

.burger.active {
  margin-top: -20px;
}

.burger.active span:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 13px;
}

.burger.active span:nth-child(2) {
  opacity: 0;
}

.burger.active span:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -10px;
}

.procesWarzenia {
  margin: 4rem 0 2rem;
}

.procesWarzenia .container-fluid {
  background-color: #D6D6D6 !important;
  padding-left: 0;
  padding-right: 0;
}

.procesWarzenia__img {
  text-align: center;
}

h2.procesWarzenia__title {
  font-family: 'caviar_dreamsbold';
  font-size: 30px;
  line-height: 44px;
  padding: 2rem 0 1rem 0;
  text-transform: uppercase;
  text-align: center;
}

.naszePiwa {
  margin: 3rem 0;
  background: none;
}

.naszePiwa__title {
  font-family: 'caviar_dreamsbold';
  font-size: 30px;
  line-height: 44px;
  padding: 2rem 0 1rem 0;
  text-transform: uppercase;
  text-align: center;
}

.naszePiwa__Text {
  padding: 0 20px;
  color: #4f4f4f;
  font-size: 14px;
  line-height: 21px;
  font-family: Poppins,sans-serif;
}

.naszePiwa__icon {
  margin: 0 auto;
}

.naszePiwa__icon img {
  text-align: center;
  margin: auto;
}

.wesela {
  background-color: #e9e9ea !important;
  padding-top: 3rem;
}

.wesela .container-fluid {
  background-color: #E9E9EA;
  padding: 0;
}

.wesela__tytul {
  font-size: 30px;
  text-transform: uppercase;
  font-family: 'caviar_dreamsbold';
  text-align: center;
  margin: 1rem 0 2rem 0;
}

.wesela__naszeHale {
  padding: 0 0 3rem 0;
  background: none;
}

.wesela__naszeHale__title {
  font-family: 'caviar_dreamsbold';
  font-size: 30px;
  line-height: 44px;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
}

.wesela__naszeHale__Text {
  padding: 0;
  color: #4f4f4f;
  font-size: 14px;
  line-height: 21px;
  font-family: Poppins,sans-serif;
  margin: 0 -15px;
}
