.yellowBigBtn,
.blueBigBtn{
    border-radius:0;
    padding: 8px;
    font-size:12px;
    width:100%;
    max-width: 140px;
    margin-bottom:2px;
    margin-top:2px;
    text-transform: uppercase;
    display: none;

    @media(min-width:350px){
        font-size: 13px;
    }
}
.button {
    border: 1px solid #4F4F4F;
    padding: 1rem;
    text-transform: uppercase;
    text-align: center;
    max-width: 200px;
    cursor: pointer;
    &:hover{
        text-decoration: none;
        color: #000;
    }
}
.buttonWrapper {
    margin: 2rem 0 0 0;
    // @media (max-width: 991px) {
    //     margin: 2rem 0 0 0;
    // }
}