.header{
    // .grow { 
    //     transition: all .2s ease-in-out; 
    //         @media (max-width: 991px) {
    //             transition: none;
    //         }
    //     }        
    // .grow:hover { 
    //     transform: scale(1.1); 
    //     @media (max-width: 991px) {
    //         transform: none;
    //     }
    // }
    &__slide {
        background-size: cover;
        height: 100vh;
        width: 100%;
        background-position: center;
        padding: 0;    
        @media (max-width: 991px) {
            height: 50vh;
        }
    }
    &__gradient {
        background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
        position: relative;
        z-index: 10;
    }
    h2.header__title {
        color: #fff!important;
        text-transform: uppercase!important;
        font-family: 'caviar_dreamsbold';
        font-size: 4rem;
        line-height: 72px;
        text-align: center;
        position: relative;
        z-index: 10;
        top: -50%;
        left: 50%;
        transform: translate(-50%,-50%);
        @media  (max-width: 1500px) and (min-width: 992px) {
            font-size: 40px;
        }
        &__gradient {
            background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
            position: relative;
            z-index: 10;
        }/* 
        &:hover {
            font-size: 4.5rem;
            transition: 1s
        } */
    }
    /* 
    #SLiderTop1:hover h2.header__title, 
    #SLiderTop2:hover h2.header__title, 
    #SLiderTop3:hover h2.header__title {        
        font-size: 4.5rem;
        transition: 1s
    } */
    #slide1 {    
        background-image: url("../../dist/images/slide1.jpg");
        background-size: 260%;
        background-position: -13rem 70%;
            @media (max-width: 991px) {
                height: 50vh;
            }
        &:hover{
            background-size: 400%!important;
            transition: 2s;
        }
    }
    #slide2 {    
        background-image: url("../../dist/images/slide2.jpg");
        background-size: 260%;
        &:hover{
            background-size: 400%!important;
            transition: 2s;
        }
    }
    #slide3 {    
        background-image: url("../../dist/images/slide3.jpg");
        background-size: 260%;
        &:hover{
            background-size: 400%!important;
            transition: 2s;
        }
    }
    &__slideContainer {
        padding: 0;
        height: 100vh;
        cursor: pointer;
        @media (max-width: 991px) {
            height: 50vh;
        }
        &:hover{
            h2.header__title{
                font-size: 4.5rem;
                transition: 1s
            }
        }
    }
    
    
    &__Logo {
        position: fixed;
        left: 50%;
        transform: translate(-50%,-15%);
        z-index: 20;
        &__main{
            top: 15%;
            &__img{
                height: 164px;
                @media (max-width: 991px) {
                    display: none;
                }
            }
        }
        &__fixed{    
            display: none;   
            top: 0;
            margin-top: 10px;
            &__img{
                height: 45px;
                width: auto;
                text-align: center;
                margin: 4px auto 0 auto;
            }
        }
    }
    
    &__fixed{
        position: fixed;
        top: 0;
        width: 100%;
        background-color: rgba(255,255,255,0.8);
        margin: 0;
        padding: 10px 0;
        .nav-item.active{
            border-bottom: 1px solid #000;
        }
        .nav-link{
            color: #000!important;
        }
        &__hidden {
            display:none;
        }
        &__showRestMenu {
            display:block!important;
        }
        .header__logoSticky{
            display: block!important;
        }
    }
    &__logoSticky{
        display: none;
    }
}
