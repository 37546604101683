.procesWarzenia {
    margin: 4rem 0 2rem;
    .container-fluid {
        background-color: #D6D6D6!important;
        padding-left: 0;
        padding-right: 0;
    }
    &__img {
        text-align: center;
    }
}
h2.procesWarzenia__title {
    font-family: 'caviar_dreamsbold';
    font-size: 30px;
    line-height: 44px;
    padding: 2rem 0 1rem 0;
    text-transform: uppercase;
    text-align: center;
}
