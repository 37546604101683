.footer {
    margin-bottom: -3rem;
    .menu-item .nav-link {
        color: black!important;
        text-transform: lowercase!important; 
        font-family: 'Poppins';
        font-size: 15px;
        padding: 0;
        &::after {
            content: "|";
            font-weight: 500;
            margin: 0 1rem;
            @media (max-width: 991px) {
                display: none;
                // content: "";
            }
        }
    }
    .menu-item-68 .nav-link {
        &::after {
            content: "";
        }
    }
    &__firstSection {
        background-color: #F5F5F5;
        padding: 5rem 15px;
    }
    &__secondSection {
        background-color: #E9E9EA;
        padding: 3rem 0 0 0;
    }
    &__copyPadding {
        padding-left: 15px;
        padding-right: 15px;
    }
    &__noPadding {
        padding-left: 0;
        padding-right: 0;
    }
    &__daneKontaktowe {
        margin: auto;
        @media (max-width: 991px) {
            margin: 3rem auto;
        }
    }
    &__form {
        @media (max-width: 991px) {
            margin: 4rem auto 0 auto;
        }
    }
    ul#menu-menu-footer-pl.footer__Top__navFooter {
        display: flex;
        text-align: center;
        @media (max-width: 991px) {
            display: block;
            text-align: center;
        }
    } 
    &__line {
        border-top: 1px solid black;
        height: 1px;
        margin: 1rem 0;
    }     
    &__tuJestesmy {
        font-size: 24px;
        line-height: 44px;
        color: #000000;
        font-family: 'caviar_dreamsbold';
        text-align: center;
        margin-bottom: 1rem;
        text-transform: uppercase;
    }
    &__kontakt {
        font-size: 15px;
        line-height: 25px;
        color: #000000;
        font-family: 'Poppins', sans-serif;;
        text-align: center;
        p {
            text-align: center;
            font-size: 15px;
        }
    }  
    &__socialMedia {
        padding-bottom: 2rem;
    }
    h3.footer__formHeader  {
        font-size: 30px;
        line-height: 44px;
        color:black;
        font-family: 'caviar_dreamsbold';
        text-align: center;
        margin-bottom: 2rem;
        text-transform: uppercase;
    } 
    input.wpcf7-form-control.wpcf7-submit {
        display: block;
        text-align: center;
        font-size: 18px;
        line-height: 44px;
        color:white;
        background-color: #343434;
        font-family: 'caviar_dreamsbold';
        float: right;
        text-transform: uppercase;
        padding: 0 1rem;
    }
    input#form_email, input#form_name {
        border: 0;
        border-bottom: 1px solid #343434;
        background-color: #F5F5F5;
        width: 100%;
        font-family: 'Poppins';
        font-weight: 300;
        font-size: 14px;
    }
    input#form_email, textarea#form_text {
        margin-top: 3rem;
        background-color: #F5F5F5;
        width: 100%;
        font-family: 'Poppins';
        font-weight: 300;
        font-size: 14px;
    }
    .map {
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
        width: 100%;
    }
    &__mapContainer {
        text-align: center;
    }
    .wpcf7 form.failed .wpcf7-response-output, .wpcf7 form.aborted .wpcf7-response-output, .wpcf7 form .wpcf7-response-output {
        margin-top: 4rem!important;
    }
    span.wpcf7-list-item {
        margin: 0!important;
    }
    .wpcf7-list-item-label {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        color: #4F4F4F;
        padding-left: .5rem;
    }
    &__copy {        
        font-size: 12px;
        line-height: 14.4px;
        color: #000000;
        font-family: 'Lato';
    }
    &__tripIcon img {
        margin-top: .4rem;
    }
    &__logo {
        margin: 0 auto 2rem auto;
        text-align: center;
    }
}


