.realizacje {
    .container-fluid {
        background-color: #E9E9EA;
        padding: 5rem 0 0;
    }
    div.carousel-item {
        opacity: 0.48;
        display: flex;
    }
    div.carousel-item.active {
        opacity: 1;
        text-align: center;
        margin: 0 auto;
        position: relative;
        z-index: 10;
        img {
            text-align: center;
            margin: 0 auto;
        }
    }
    div.owl-item.cloned, div.owl-item.active,  div.owl-item,  .owl-item {
        max-width: 930px!important;
        max-height: 617px!important;
    }
    &__tytul {
        font-size: 30px;
        text-transform: uppercase;
        font-family: 'caviar_dreamsbold';
        text-align: center;
        margin: 1rem 0 2rem 0;
    }
    .owl-item:not(.center) {
        opacity: 0.48!important;
    }
    .owl-theme .owl-dots, .owl-theme .owl-nav {
        text-align: left;
        display: block!important;
    }
    .owl-carousel .owl-nav {
        text-align: center;
        display: unset;  
        
        button{
            height: 37px;
            width: 37px;
            span {
                display: none
            }
            
            &.owl-prev {
                background-image: url("../../dist/images/strzalka-lewa.svg");
            }   
            &.owl-next {
                background-image: url("../../dist/images/strzalka-prawa.svg");
            }
        }
    }

    
    &__naszeHale{        
        padding: 0 0 3rem 0;
        background: none;
        &__title {
            font-family: 'caviar_dreamsbold';
            font-size: 30px;
            line-height: 44px;
            padding: 0;
            text-transform: uppercase;
            text-align: center;
        }
        &__Text{
            padding: 0;
            color: #4f4f4f;
            font-size: 14px;
            line-height: 21px;
            font-family: Poppins,sans-serif;
            margin: 0 -15px;
        }
    }
    .container{
        p {
            margin: 1rem 0;
        }
    }
    .item{
        .textBox {
            position: absolute;
            bottom: 10%;
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            text-shadow: 3px 3px 7px #000;
            font-size: 18px;
        }
    }
    
}