.carouselSprzet {
    background-color: #D6D6D6;
    &__slide {
        text-align: right;
    }
    &__subheader {
        font-family: 'Poppins';
        font-size: 21px;
        font-weight: 400;
        @media (max-width: 991px) {
            font-size: 16px;
        } 
        @media (max-width: 767px) {
            font-size: 12px;
        }  
    }
    &__text {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 400;
        @media (max-width: 991px) {
            font-size: 12px;
        }  
        @media (max-width: 767px) {
            font-size: 10px;
        }
    }
    &__cyfra {
        font-family: 'caviar_dreamsbold';
        font-size: 20px;
        font-weight: 500;
        margin: auto;
        text-align: center;
    }
    ol.carousel-indicators {
        display: block;
        top: 0;
        width: 50%;
        padding: 5rem 2rem 5rem 15rem;
        background-color: #D6D6D6;
        margin: 0;
        @media (max-width: 1350px) {
            padding: 2rem 2rem 2rem 10rem;
        }    
        @media (max-width: 1350px) {
            padding: 1rem 2rem 1rem 2rem;
        }       
        li {
            width: auto!important;
            height: auto!important;
            background-color: transparent!important;
            text-indent: 0!important;
            border-radius: 0%;
            opacity: 1;
            .active {
                font-weight: 600;
            }
        }
        .circle {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 2px solid #A5A89D;
            margin: .6rem 1rem 0 0;
            text-align: center;
            vertical-align: middle;
            @media (max-width: 991px) {
                margin-top: 0;
            } 
        }
        li.active {
            font-weight: 600!important;

            .circle {
                background-color: #7F7F7F;
                color: white;
            }
            .carouselSprzet__subheader {
                font-weight: 600;
                color: black;
                text-align: left;                
            }
            .carouselSprzet__text {
                font-weight: 600;
                color: black;
                text-align: left;                 
            }
        }
    }
    .carousel-indicators .active {
        font-weight: 600;
    }
}