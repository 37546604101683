.bannery {
    background-color: #e9e9ea !important;
    padding-top: 2rem;
    .container-fluid {
        background-color: #E9E9EA;
        padding: 0;
    }
    .container {
        background-color: #E9E9EA;
    }
    &__additionalText{
        padding: 1rem 1rem;
    }
    &__img {
        text-align: center;
    }
    &__liczbaOsob {
        font-size: 25px;
        text-align: center;
        color: white;
        font-family: 'Poppins'; 
        font-weight: 500;
        margin-top: .5rem;      
        @media (max-width: 991px) {
            font-size: 20px;
        } 
        @media (max-width: 767px) {
            font-size: 14px;
        } 
        @media (max-width: 575px) {
            font-size: 10px;
        } 
    }
    &__text {
        position: relative;
        margin-top: -16rem;
        left: 50%;
        transform: translate(-50%,-50%);
        top: 20%;
        max-width: 1410px;
        @media (max-width: 1199px) {
            transform: translate(-50%,-20%);            
        }
        @media (max-width: 991px) {
            transform: translate(-50%,-18%);
            margin-top: -12rem;
        }
        @media (max-width: 767px) {
            margin-top: -9rem;
        }
        @media (max-width: 575px) {
            margin-top: -8rem;
            transform: translate(-50%,-30%); 
        }
        @media (max-width: 500px) {
            margin-top: -8rem;
            transform: translate(-50%,-20%); 
        }
        @media (max-width: 400px) {
            margin-top: -8rem;
            transform: translate(-50%,-10%); 
        }
        @media (max-width: 370px) {
            margin-top: -6rem;
            transform: translate(-50%,-20%);
        }
    }
    &__header {
        font-family: 'caviar_dreamsbold';
        font-size: 40px;
        text-transform: uppercase;
        color: white;
        text-align: center;  
        font-weight: 700;  
        @media (max-width: 991px) {
            font-size: 25px;
        } 
        @media (max-width: 767px) {
            font-size: 20px;
        } 
        @media (max-width: 575px) {
            font-size: 16px;
        } 
        @media (max-width: 400px) {
            font-size: 14px;
        } 
    }
    &__rozmiar {
        font-family: 'Poppins';
        font-size: 24px;
        color: white;
        text-align: center; 
        font-weight: 700;   
        margin-bottom: 2rem;
        @media (max-width: 991px) {
            font-size: 16px;
            margin-bottom: 1rem;
        } 
        @media (max-width: 575px) {
            font-size: 12px;
            margin-bottom: .5rem;
        } 
        @media (max-width: 400px) {
            font-size: 12px;
            margin-bottom: 0;
        } 
    }
    &__smallText {
        font-family: 'Poppins';
        font-size: 12px;
        color: white;
        text-align: center;
        text-transform: uppercase; 
        letter-spacing: 3px;
        margin-bottom: 1rem;
        @media (max-width: 767px) {
            font-size: 10px;
            margin-bottom: .5rem;
        } 
        @media (max-width: 400px) {
            font-size: 8px;
            margin-bottom: .2rem;
            letter-spacing: 1px;
        } 
    }
    &__module {
        text-align: center;
        border-right: 1px solid white;
        &--last {
            border: none;
        }
        img {
            @media (max-width: 991px) {
                width: 40px;
            } 
            @media (max-width: 767px) {
                width: 30px;
            } 
        }
    }
    &__naszeHale{        
        padding: 0 0 3rem 0;
        background: none;
        &__title {
            font-family: 'caviar_dreamsbold';
            font-size: 30px;
            line-height: 44px;
            padding: 0;
            text-transform: uppercase;
            text-align: center;
        }
        &__Text{
            padding: 0;
            color: #4f4f4f;
            font-size: 14px;
            line-height: 21px;
            font-family: Poppins,sans-serif;
            margin: 0;
        }
    }
}