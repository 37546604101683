@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400&display=swap');
body{
    font-family: 'Lato', sans-serif;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 10, 2020 */

@font-face {
    font-family: 'caviar_dreamsbold';
    src: url('../../fonts/caviardreams_bold-webfont.eot');
    src: url('../../fonts/caviardreams_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/caviardreams_bold-webfont.woff2') format('woff2'),
         url('../../fonts/caviardreams_bold-webfont.woff') format('woff'),
         url('../../fonts/caviardreams_bold-webfont.ttf') format('truetype'),
         url('../../fonts/caviardreams_bold-webfont.svg#caviar_dreamsbold') format('svg');
    font-weight: normal;
    font-style: normal;
}